const questions = [
  { name: 'От скольки подписчиков доступна статистика в телеграм-канале?', answers: [
    { name: 'от 100' },
    { name: 'от 500' },
    { name: 'от 50' },
  ] },
  { name: 'Как правильно поступить с негативным отзывом?', answers: [
    { name: 'Удалить комментарий' },
    { name: 'Вежливо ответить' },
    { name: 'Написать в личные сообщения автору ругательства' },
  ] },
  { name: 'Как продвинуть свой канал в телеграм?', answers: [
    { name: 'Спамить в комментариях больших каналов' },
    { name: 'Использовать хештеги' },
    { name: 'Договориться с автором блога о рекламе' },
  ] },
  { name: 'Как часто публиковать посты в канал?', answers: [
    { name: 'Каждый день несколько раз, чтоб не забывали' },
    { name: 'Раз в месяц появляться и здороваться с подписчиками' },
    { name: 'Составить индивидуальный контент-план ' },
  ] },
  { name: 'Что написать в шапку профиля канала?', answers: [
    { name: 'Шутку' },
    { name: 'Инфо о канале' },
    { name: 'Рассказать о себе' },
  ] },
  { name: 'Как добавить 100 подписчиков в закрытый телеграмм канал?', answers: [
    { name: 'Использовать специальный бот (например, BotPay)' },
    { name: 'Добавлять руками самому' },
    { name: 'Открыть канал и всех пустить, потом закрыть' },
  ] },
  { name: 'Как удержать внимание подписчиков?', answers: [
    { name: 'Публиковать много постов в день' },
    { name: 'Форматировать текст' },
    { name: 'Публиковать лонгриды' },
  ] },
  { name: 'Что делать если Телеграм-канал заблокировали?', answers: [
    { name: 'Написать в бот @spambot ' },
    { name: 'Подождать 24 часа' },
    { name: 'Написать в поддержку' },
  ] },
  { name: 'Как подписчикам удобнее производить оплату в закрытом ТГ-канале?', answers: [
    { name: 'Через платёжную систему BotPay' },
    { name: 'Через реквизиты карты' },
    { name: 'Перевод по номеру счёта ' },
  ] },
  { name: 'На какую тему можно вести закрытый телеграм-канал?', answers: [
    { name: 'Любая уникальная информация' },
    { name: 'Тренировки и консультации' },
    { name: 'Собственный курс' },
  ] },
  
];

export const getCurrentQuestion = (index: number) => {
  return questions[index];
}