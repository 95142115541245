
import { defineComponent } from "vue-demi";
import Admin from "@/components/newyear/index.vue";

export default defineComponent({
  name: "NewYearView",
  components: {
    Admin,
  },
});
